<template>
  <header 
    class="fixed top-0 left-0 block w-full pc:z-[99] mobile:w-[100vw] mobile:z-[9999]"
    :class="headerOpened ? 'bg-[#111111] text-white' : 'bg-white text-orange-500'"
  >
    <div 
      v-if="$screen.breakpoint === 'pc'" 
      class="px-[50px] relative flex justify-between h-14 bg-white"
    >
      <div 
        class="text-gray-600 relative w-[129px] h-[28px] mt-3.5" 
        @click="headerOpened = false"
      >
        <img 
          src="@/assets/header/cookapps.svg" 
          width="129" 
          height="28" 
          alt="쿡앱스 메인" 
          class="w-[129px]"
        >
        <router-link 
          to="/" 
          class="absolute top-0 h-full left-0 w-[129px]" 
        />
      </div>
      <div class="flex items-center gap-10">
        <router-link 
          to="/about" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>About</div>
          </div>
        </router-link>
        <router-link 
          to="/culture" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>Culture</div>
          </div>
        </router-link>
        <router-link 
          to="/games" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>Games</div>
          </div>
        </router-link>
        <router-link 
          to="/story" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>Story</div>
          </div>
        </router-link>
        <!-- <a
          href="https://gamemakers.community" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>Social Club</div>
          </div>
        </a> -->
        <router-link 
          to="/publishing" 
          class="text-gray-600 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
        >
          <div class="flex items-center hover:opacity-50">
            <div>Publishing</div>
          </div>
        </router-link>
        <div 
          class="text-gray-600 cursor-pointer h-14 inline-block pt-3.5 text-xl font-bold leading-[1.3] -tracking-[0.2px]"
          @mouseover="careersOvered = true" 
          @mouseleave="careersOvered = false"
        >
          <div class="flex h-full">
            <div>
              <span :style="{ 'opacity': careersOvered ? 0.5 : 1 }">
                Careers
              </span>
            </div>
            <img 
              :style="{ opacity: careersOvered ? 0.5 : 1, transform: careersOvered ? 'scaleY(-1)' : 'scaleY(1)', transition: '.2s' }" 
              class="pl-1 w-5 h-5 mt-1 ml-[3px]"
              src="@/assets/header/icon-arrow-down.svg" 
            >
            <div>
              <div 
                v-show="careersOvered"
                class="absolute top-14 right-0 w-[166px] px-[1px] py-1 rounded-[4px] bg-white border border-gray-200 shadow-[0_4px_12px_0_rgba(0,0,0,.15)]"
              >
                <router-link
                  class="inline-block w-full h-10 px-4 py-2.5 text-base leading-[1.25] text-[#666] hover:bg-[#f5f5f5] active:bg-[#efefef]"
                  to="/careers/list" 
                  @click.native="headerOpened = false"
                >
                  <div class="flex justify-between w-full h-full font-normal">
                    채용 공고
                    <div
                      class="w-[30px] h-[18px] rounded-[10px] bg-orange-50 text-orange-500  flex justify-center items-center font-bold text-[13px]"
                    >
                      {{ jobsCount }}
                    </div>
                  </div>
                </router-link>
                <router-link
                  class="inline-block w-full h-10 px-4 py-2.5 text-base leading-[1.25] text-[#666] font-normal hover:bg-[#f5f5f5] active:bg-[#efefef]"
                  to="/faq" 
                  @click.native="headerOpened = false"
                >
                  <div class="w-full h-full">
                    FAQ
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="relative flex mx-4"
      :class="headerOpened ? 'bg-[#111111] text-white' : 'bg-white text-orange-500'"
    >
      <div class="flex flex-row justify-between w-full h-14">
        <div class="mt-[15px] relative w-[205px] h-[28px]">
          <img 
            src="@/assets/header/cookapps.svg" 
            width="129" 
            height="28" 
            alt="쿡앱스 메인" 
            class="w-[129px]"
          >
          <router-link 
            to="/" 
            class="absolute top-0 h-full left-0 w-[129px]" 
          />
        </div>
        <div class="block">
          <button 
            class="hamburger__btn mt-4" 
            @click="headerOpened = !headerOpened"
          >
            <img
              v-if="!headerOpened"
              src="@/assets/svg/menu2.svg"
              class="fill-gray-600 w-6 h-6"
              alt="메뉴 버튼"
            >
            <img
              v-if="headerOpened"
              src="@/assets/svg/close.svg"
              class="fill-gray-600 w-6 h-6"
              alt="닫기 버튼"
            >
          </button>
        </div>
      </div>
      <div 
        v-if="headerOpened" 
        class="fixed left-0 w-full h-[calc(100vh-56px)] font-ClashDisplayMedium font-normal bg-[#111111] text-gray-200 top-14"
      >
        <div class="px-4">
          <div>
            <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[55px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="/about" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] mt-[11px] mb-4">
                About
              </div>
            </router-link>
            <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="/culture" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] my-4">
                Culture
              </div>
            </router-link>
            <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="/games" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] my-4">
                Games
              </div>
            </router-link>
            <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="/story" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] my-4">
                Story
              </div>
            </router-link>
            <!-- <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="https://gamemakers.community/" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] my-4">
                Social Club
              </div>
            </router-link> -->
            <router-link 
              tag="div" 
              class="block m-0 border-t border-solid border-t-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              to="/publishing" 
              @click.native="headerOpened = false"
            >
              <div class="h-[26px] my-4">
                Publishing
              </div>
            </router-link>
            <div 
              class="block m-0 border-y border-solid border-y-[rgba(255,255,255,0.15)] h-[60px] text-xl font-medium leading-[1.3] tracking-[0.5px]"
              @click="careersOpened = !careersOpened"
            >
              <div class="flex items-center justify-between w-full">
                <div>
                  <div 
                    class="h-[26px] my-4" 
                    :style="{ opacity: careersOvered ? 0.5 : 1 }"
                  >
                    Careers
                  </div>
                </div>
                <img 
                  class="career_arrow_icon inline-block w-[20px] h-[20px]" 
                  :style="{ opacity: careersOpened ? 0.5 : 1, transform: careersOpened ? 'scaleY(-1)' : 'scaleY(1)', transition: '.2s' }" 
                  src="@/assets/header/career_arrow_down.svg" 
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <transition name="slide-down">
          <div 
            v-show="careersOpened"
            class="flex flex-col py-5 gap-5 font-Pretendard font-bold text-gray-200 bg-black tablet:h-[100px] tablet:text-base tablet:leading-[1.25] mobile:h-[108px] mobile:text-lg mobile:leading-6"
          >
            <router-link 
              tag="div" 
              class="flex items-center px-4 cursor-pointer jutify-center tablet:h-5 mobile:h-6"
              to="/careers/list" 
              @click.native="headerOpened = false"
            >
              채용 공고
              <div class="flex items-center justify-center ml-1.5 w-[26px] h-4 rounded-[9px] bg-white mobile:ml-2">
                <div class="text-[12px] text-gray-800 -tracking-[0.05px] tablet:leading-[18px] mobile:leading-4">
                  {{ jobsCount }}
                </div>
              </div>
            </router-link>
            <router-link 
              tag="div" 
              class="px-4 cursor-pointer tablet:h-5 mobile:h-6" 
              to="/faq"
              @click.native="headerOpened = false"
            >
              자주 묻는 질문
            </router-link>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
// import Subscribe from './Subscribe.vue';
import { getOpenJobs } from '@/api/job';

export default {
  // components: { Subscribe },
  data() {
    return {
      headerOpened: false,
      careersOvered: false,
      careersOpened: false,
      jobsCount: 0,
    };
  },
  async created(){
    const result = await getOpenJobs(); //get jobs
    this.jobsCount = result.data.items.length;
  },
  methods:{
    async logOut() {
      if (window.navigator.userAgent.indexOf('Trident') > -1) {
        this.applicantForm.name = document.getElementsByName('namefield')[0].value;
      }
      document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      await this.$store.dispatch('user/setToken', '');
      await this.$store.dispatch('user/setUid', '');
      await this.$store.dispatch('user/setUserForm', {});
      alert('로그아웃 되었습니다.');
      this.$router.push('/');
    },
  }

};
</script>

<style lang="scss" scoped>
.hamburger__btn i {
  position: absolute;
  right: 0px;
  top: 15px;
  transition: transform 0.2s;
  /* color: #666; */
  font-size: 1.6rem;
  transform: scale(1);
}

.hamburger__btn i.xi-bars {
  font-size: 1.8rem;
}

.hamburger__btn i.off {
  transform: scale(0);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition:  max-height .4s, opacity.6s;
}

.slide-down-enter-to,
.slide-down-leave {
  overflow: hidden;
  max-height: 1000px;
  opacity: 1;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
  opacity: .0;
}
</style>
